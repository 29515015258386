*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box
}

body {
	margin: 0;
}

body, 
p {
	font-family: $font;
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	color: #000;
}

h1{
	font-family: $font;
	font-size: 30px;
	font-weight: bold;
	line-height: 40px;
	margin:0 0 30px 0;
	padding:0;

	@media (min-width: $md) {
		font-size: 40px;
	}
}

h2 {
	font-family: $font;
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 20px;
}

a {
	font-family: $font;
	font-weight: 600;
	color: #000;
	&:hover {
		color: $blue;
	}
}

