
header {
	height: 82px;
	box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.05);
	position: fixed;
	top: 0;
	width: 100%;
	background-color: #fff;
	z-index: 2;
	display: flex;
	align-items: center;

	.logo {
		img {
			width: 60px;
			height: 60px;
		}
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.menu-mobile {
		@include max($lg) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
		.menu-toggler {
			display: none;
			@include max($lg) {
				display: inline-block;
				border: 2px solid $blue;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-image: url('images/icons/menu-open.svg'); 
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}

	.menu {
		display: flex;
		align-items: center;

		@include max($lg) {
			display: none;
		}

		a {
			text-decoration: none;
		}
		ul {
			display:flex;
			align-items: center;
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				a{
					padding: 5px 10px;
					margin: 0 10px;
					text-transform: uppercase;
				}

				.menu--contact {
					color: #fff;
					background-color: $blue;
				}
			}
		}
	}
}

.menuSelected {
		color: $blue;
}

.nav--open {
	@include max($lg) {
		.menu {
			display: block;
			align-items: center;
			position: absolute;
			right: 0;
			top: 82px;
			width: 208px;
			background-color: #fff;
			z-index: 1;
			-webkit-box-shadow: 0px 10px 13px -7px rgba(0,0,0,.4), 5px 5px 15px 5px rgba(0,0,0,0); 
			box-shadow: 0px 10px 13px -7px rgba(0,0,0,.4), 5px 5px 15px 5px rgba(0,0,0,0);
			ul { 
				flex-direction: column;
				align-items: flex-end;
				padding-top: 12px;
				li {
					padding-bottom: 12px;
				}
			}
			.lang {
				text-align: right;
    		padding-right: 20px;
    		padding-bottom: 20px;
			}
		}
	
	}
}
