footer {
	border-top: 5px solid $blue;
	padding: 50px;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include max($md) {
			flex-direction: column;
		}

		.logo{
			padding-right: 30px;
			img {
				width: 60px;
				height: 60px;
			}
			@include max($md) {
				padding: 30px 0;
			}
		}
		.member{
			border-right: 1px solid #BDC7D0;
			border-left: 1px solid #BDC7D0;
			padding-right: 30px;
			padding-left: 30px;
			flex-grow: 1;

			@include max($md) {
				border:0;
				text-align: center;
				padding: 24px 0;
			}

			span {
				text-transform: uppercase;
				color: $blue;
				font-weight: bold;
				display: inline-block;
				padding-bottom: 12px;
			}
			img{
				margin-right: 30px;
				@include max($md) {
					margin: 24px 12px;
				}
			} 
		}

		.social{
			border-right: 1px solid #BDC7D0;
			padding-left: 30px;
			@include max($md) {
				padding: 24px 0px;
				border: 0;
				border-top: 1px solid #BDC7D0;
				text-align: center;
			}
			span {
				text-transform: uppercase;
				color: $blue;
				font-weight: bold;
				display: inline-block;
				padding-bottom: 12px;
			}
			img{
				margin-right: 30px;
				@include max($md) {
					margin: 0 15px;
				}
			} 
		}

		.copyright {
			padding-left: 30px;
			@include max($md) {
				padding: 24px 0px;
			}
		}
	}
}