#services{
  background-color: $blue;
  padding: 40px 0 80px 0;

  h1 {
    color:#fff;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 16px;

    @include max($lg) {
      flex-wrap: wrap;
    }

    @include max($md) {
      flex-direction: column;
    }
    
    li {
      display: block;
      background-color: #fff;
      padding: 30px 20px;
      border-radius: 5px;
      flex: 1 1 0px;
      text-align: center;

      @include max($lg) {
        flex: 1 1 30%;
      }

      @include max($md) {
        flex: 1 1 0px;
      }

      img {
        margin-bottom: 20px;
      }
    }
  }
}