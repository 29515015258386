$font: 'Work Sans', Arial, sans-serif;;

$beige: #f0eae5;
$gray-light: #f6f6f6;
$orange: #e0821e;
$green: #94af30;
$brown: #2f251b;
$marine: #101d49;
$dark-100: #1f2041;

$blue: #4BA4D5;
$dark-blue: #2161BF;
$green: #7EBF90;
$gray: #ADB5CC;
$light-gray: #E1E3E7;

$sm: 576px;
$md: 768px; 
$lg: 992px; 
$xl: 1200px;
$xxl: 1400px;

$containerXXL: 1320px;
$containerXL: 1140px;
$containerLG: 960px;
$containerMD: 730px;
$containerSM: 540px;
$containerXS: 100%; 

@mixin min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin max($size) {
  @media (max-width: $size - 1px) {
    @content;
  }
}