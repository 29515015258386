#js-industry{
  background-color: $blue;
  display: flex;
  margin-bottom: 40px;

  .industry-content-desktop {
    display: flex;
    width: 100%;
    @media (max-width: $md) {
      display: none;
    }
  }
  .industry-content-mobile {
    display: none;
    @media (max-width: $md) {
      display: block;
      width: 100%;
    }
  } 
}

.industry-content-desktop {

  .industry-content,
  #js-industry-img {
    flex: 1 1 0px;
  }

  .industry-content {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
    padding-bottom: 80px;
    padding-right: 20px;


    .industry-container {
      width: calc(1320px * 0.5 - 24px);
      margin-left: 0;
      margin-right: 0;

      @media (max-width: $xxl) {
          width: calc(1140px * 0.5 - 24px);
      }
      @media (max-width: $xl) {
          width: calc(960px * 0.5 - 24px);
      }
      @media (max-width: $lg) {
          width: calc(730px * 0.5 - 24px);
      }
      @media (max-width: $md) {
         // width: calc(540px * 0.5 - 24px);
         width: 100%;
         margin-left: auto;
         margin-right: auto;
      }
      @media (max-width: $sm) {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
      }

    }
  }

  h1 {
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: normal;
      padding: 5px 10px;
      display: block;
    }
  }

  .industry-text {
    display: flex;

    ul{
      margin: 0;
      padding:0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        color: #fff;
        text-decoration: none;
        font-weight: normal;
        padding: 8px 10px;
        margin-bottom: 5px;
        display: block;
        font-size: 20px;
        margin-right: 20px;
        flex: 0 0 35%;

        &:nth-child(even){
          flex: 0 0 55%;
        }

        @media (max-width: $lg) {
          flex: 0 0 100%;
        }
        
        &:hover {
          background-color: rgba(225, 227, 231, 0.2);
          cursor: pointer;
          padding-left: 20px;
        }

        path {
          fill: #fff;
        }
      }
    }
  }
}

#js-industry-img {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}
.js-aviation {
  #js-industry-img {
    background-image: url('images/industry/aviation.jpg');
  }
  #js-aviation{
    background-color: rgba(225, 227, 231, 0.2);
    cursor: pointer;
    padding-left: 20px;
  }
}
.js-defense {
  #js-industry-img {
    background-image: url('images/industry/defense.jpg');
  }
  #js-defense{
    background-color: rgba(225, 227, 231, 0.2);
    cursor: pointer;
    padding-left: 20px;
  }
}

.js-medical {
  #js-industry-img {
    background-image: url('images/industry/medical.jpg');
  }
  #js-medical{
    background-color: rgba(225, 227, 231, 0.2);
    cursor: pointer;
    padding-left: 20px;
  }
}

.js-rail {
  #js-industry-img {
    background-image: url('images/industry/rail.jpg');
  }
  #js-rail{
    background-color: rgba(225, 227, 231, 0.2);
    cursor: pointer;
    padding-left: 20px;
  }
}

.js-transport {
  #js-industry-img {
    background-image: url('images/industry/transport.jpg');
  }
  #js-transport{
    background-color: rgba(225, 227, 231, 0.2);
    cursor: pointer;
    padding-left: 20px;
  }
}

.js-industry {
  #js-industry-img {
    background-image: url('images/industry/industry.jpg');
  }
  #js-industries{
    background-color: rgba(225, 227, 231, 0.2);
    cursor: pointer;
    padding-left: 20px;
  }
}


.industry-content-mobile  {
  padding: 20px 0 0 0;

  &>.container {
    padding: 0;
  }

  h1 {
    color: #fff; 
    padding-left: 36px;
  }
  .slickslider--industry {
    h2 {
      text-align: center;
    }
    img {
      width: 100%;
    }
    .slick-arrow {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      border: 2px solid;
      border-color: #fff;
      background-color: $blue;
      position: absolute;
      top: 0;
      font-size: 0;
      line-height: 0;
      padding-top: 5px;
      cursor: pointer;
      background-image: url('images/icons/arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 16px;

    }
    .slick-next {
      right: 36px;
    }

    .slick-prev {
      transform: rotate(180deg);
      z-index: 1;
      left: 36px;
    }
  }

  .dots--industry {
    margin: -40px auto 14px auto;
    text-align: center;
    position: relative;
    list-style: none;
    width: 100%;
  	overflow: hidden;

    li {
      display: inline-block;
      border-radius: 50%;
      margin: 0px; 
      width: 12px;
      height: 12px;
      line-height: 19px;
      text-align: center;
      vertical-align: middle;
      border: 2px solid;
      border-color:rgba(255,255,255,.5);
      margin-left: 6px;
      margin-right: 6px;
    }
    	
    li.slick-active {
      border: 1px solid;
      border-color:rgba(255,255,255,0.5);
      background-color: #fff;
    }
}
}