#contact{

    $containerXXL: 1320px;
    $containerXL: 1140px;
    $containerLG: 960px;
    $containerMD: 730px;
    $containerSM: 540px;
    $containerXS: 100%; 

    background-color: $blue;
    min-height: 600px;
    margin-bottom: 40px;
    
    .row {
        @include max($md) {
            flex-direction: column;
          }
    }

    .col-address {
        background-image: url('images/img_contact.jpg');
        min-height: 600px;
        display: flex;
        justify-content: flex-end;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: $md) {
            width: 100%;
            background-size: cover;
            background-position: center bottom;
            height: 700px;
            
        }
        @media (min-width: $xl) {
            background-image: url('images/img_contact-h.jpg');
            min-height: 700px;
            
        }
        
    }

    .col-form {
        background-color: #fff;
        min-height: 600px;
        display: flex;
        justify-content: flex-start;
        @media (max-width: $md) {
            width: 100%;
        }
    }

   .address-container {
       padding-top: 40px;
       padding-right: 40px;
        width: calc(1320px * 0.4 - 24px);
        @media (max-width: $xxl) {
            width: calc(1140px * 0.4 - 24px);
        }
        @media (max-width: $xl) {
            width: calc(960px * 0.4 - 24px);
        }
        @media (max-width: $lg) {
            width: calc(730px * 0.4 - 24px);
        }
        @media (max-width: $md) {
            //width: calc(540px * 0.4 - 24px);
            width: 100%;
            padding-left: 24px;
            padding-right: 24px;
        }
        @media (max-width: $sm) {
            width: 100%;
        }

        h1{color: #fff;}
        p {
            color: #fff;
            font-size: 18px;
            line-height: 1.4;
        }
   }

   .form-container {
        padding-top: 40px;
        padding-left: 40px;
        width: calc(1320px * 0.6 - 24px);
        @media (max-width: $xxl) {
            width: calc(1140px * 0.6 - 24px);
        }
        @media (max-width: $xl) {
            width: calc(960px * 0.6 - 24px);
        }
        @media (max-width: $lg) {
            width: calc(730px * 0.6 - 24px);
        }
        @media (max-width: $md) {
            //width: calc(540px * 0.6 - 24px);
            width: 100%;
            padding-right: 24px;
            padding-left: 24px;
        }
        @media (max-width: $sm) {
            width: 100%;
        }
   }


  label {
      font-weight: bold;
      color: $green;
      display: block;
      margin-bottom: 10px;
      margin-top: 20px;
  }

  .file {
      display: flex;
      border: 2px solid $blue;
      border-radius: 5px;
      align-items: center;
  }

  .clip {
      display: inline-block;
      padding: 5px;
      margin-right: 5px;
  }

    input, textarea {
      width: 100%;
  }

  textarea {
    height: 100px;
}
  
  .g-recaptcha {
    margin-top: 20px;
    text-align: right;
    &>div {
        width: auto !important;
    }
  }

  .clip {
      background-color: $blue;
  }
  
  
  .form--submit {
      margin-top: 20px;
      text-align: right;

      button {
          background-color: $blue;
          color: #fff;
          border: 0;
          font-weight: bold;
          padding: 10px 40px;
      }
  }
  
    .formError {
        background-color:rgba(255,0,0,0.05);
        padding: 10px;
        border: 1px solid;
        border-color: rgba(255,0,0,0.2);
        margin-bottom: 30px;
        color: #990000;
    }
    
    .formErrorList {
        margin-bottom: 0;
    }
        
    .formSucess {
        background-color:rgba(0,155,0,0.05);
        padding: 10px;
        border: 1px solid;
        border-color: rgba(0,155,0,0.2);
        margin-top: 30px;
        color: #006600;
    }
  
}

