#about {
		padding-top: 82px;
	
	.slick-slide.slide {
		height: 384px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
	
		@media (max-width: $xl) { 
			height: 420px;
		}
	
		@media (max-width: $lg) { 
			height: 500px;
			background-position: -500px;
			background-size: none;
		}
	
		@media (max-width: $md) { 
			height: 750px;
			background-size: contain;
			background-position: center bottom;
			padding-bottom: 30px;
		}
	}
	
	.caption {
		width: 50%;
	
		@media (max-width: $md) { 
			width: 90%;
			margin: 80px auto auto auto;
		}
		
		p {
			font-size: 16px;
		}
	}
	
	.slide>.container {
		@media (max-width: $lg) { 
			background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 80%);
			height: 100%;
			display: flex;
			align-items: center;
		}
		@media (max-width: $md) { 
			background: none;
		}
	}
	
	.slide1 {
		@media (max-width: $md) { 
			background-image: url('images/slides/slide1_mobile.jpg') !important;
		}
	}
	.slide2 {
		@media (max-width: $md) { 
			background-image: url('images/slides/slide2_mobile.png') !important;
		}
	}
	.slide3 {
		@media (max-width: $md) { 
			background-image: url('images/slides/slide3_mobile.jpg') !important;
		}
	}
	.slide4 {
		@media (max-width: $md) { 
			background-image: url('images/slides/slide4_mobile.jpg') !important;
		}
	}
}
