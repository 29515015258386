#certification{
  padding-top: 40px;
  padding-bottom: 80px;

  p {
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding:0;
    display: flex;

    @include max($lg) {
      flex-direction: column;
    }

    li {

      flex: 1 1 0px;

    a {
      padding: 30px;
      border-bottom: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      display: block;
      height: 100%;
      width: 100%;
      text-decoration: none;
      &:first-child {
        border-left: 1px solid $light-gray;
        @include max($md) {
          border-right: 0;
          border-left: 0;
        }
      }  
      &:hover {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
        svg path {
          fill: $dark-blue;
        }
      }
      @include max($lg) {
        border-right: 0;
        border-left: 0;
      }
    }

      p {
        font-size: 20px;
        line-height: 25px;
        &::before {
          display: block;
          content: '';
          width: 60px;
          height: 4px;
          background-color: $green;
          margin-bottom: 15px;
        }
      }
      span {
        background-color: $gray;
        font-weight: bold;
        font-size: 12px;
        line-height: 25px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 3px 18px;
        margin-bottom: 20px;
        color: #fff;
        display: inline-block;
      }

    }
  }
}